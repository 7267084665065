/* eslint react/no-did-mount-set-state: 0 */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class BookDetail extends Component {
    
  state = {
    book: [],
    theid : this.props.match.params.id
   }

async componentDidMount() {
    //const {id} = this.props.match.params;
    let pagenum = 1;
    const theid = this.state.theid;
    if (theid <= 20) {
      pagenum = 1;
    } else if (theid <= 41)  {
      pagenum = 2;
    } else if (theid <= 61)  {
      pagenum = 3;
    } else if (theid <= 81)  {
      pagenum = 4;
    } else if (theid <= 101)  {
      pagenum = 5;
    }
    this.setState({
      pagenum: pagenum,
      });
}

render() {
  const thebooks =  JSON.parse(localStorage.getItem("thebooks"));
  const book = [];
  const findBooks = thebooks.filter((item) => {
     if (item.id === this.state.theid) {
       book["id"] = item.id;
       book["title"] = item.title;
       book["image"] = item.image;
       book["author_image"] = item.author_image;
       book["description"] = item.description;
       book["author"] = item.author;
       }
       return book;
     })
   
  const BOOK_PATH = 'https://www.outerspacebook.net/images/';
  const pagenum = this.state.pagenum;

 return (
  <div className="background-main" >
     
     <div className="content-area" key={book.id}>
     <div className="book-title">{book.title}</div>
     <div className="book-image-p2 clearfix"> <img src={`${BOOK_PATH}${book.image}`} alt={book.title} /></div>

     <div className="author-section">
      {book.author_image &&
     <p><img className="author-image" src={`${BOOK_PATH}${book.author_image}`}  alt={book.author}/><br/></p>
      }
      {book.author}
     </div>
     
     <div className="book-description clearfix">{book.description}</div>
     <Link to={`/${pagenum}`}><input type="button" title="back button" className="button-back"  value="&lt; BACK"/></Link>
     </div> 
      
      <div className="spacer">&nbsp;</div>
    </div>
  );

}

}


export default BookDetail;